<template>
    <div class="container">
        <div class="col-12 mb-3">
            <h2>ROI Calculator</h2>
        </div>
        <div class="card roi">
            <div class="card-body">
                <div class="row mb-5">
                    <AppSelect v-model="vesselData.vessel"
                               :options="vesselData.vessels || []"
                               track-by="name"
                               label-field="name"
                               :allow-empty="true"
                               placeholder="Select vessel"
                               @input="setValues(vesselData.vessel)"
                               class="col-lg-5 multiselect--lg roi__input"/>
                </div>
                <div class="row d-flex align-content-stretch">
                    <div class="col-lg-3 col-sm-6 mb-3 mb-lg-0">
                        <div class="d-flex flex-column justify-content-between roi__item">
                            <h6 class="mb-4"><strong>Price of Fuel USD/MT</strong></h6>
                            <AppInput class="input-group-lg mb-0 roi__input"
                                      v-model="fuelPrice"/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 mb-3 mb-lg-0">
                        <div class="d-flex flex-column justify-content-between roi__item">
                            <h6 class="mb-4"><strong>Consumption MT/Day</strong></h6>
                            <AppInput class="input-group-lg mb-0 roi__input"
                                      v-model="perDayConsumption"/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 mb-3 mb-lg-0">
                        <div class="d-flex flex-column justify-content-between roi__item">
                            <h6 class="mb-4"><strong>Saving %</strong></h6>
                            <AppInput class="input-group-lg mb-0 roi__input"
                                      v-model="saving"/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6 mb-3 mb-lg-0">
                        <div class="d-flex flex-column justify-content-between roi__item">
                            <h6 class="mb-4"><strong>Occupancy % p.a.</strong></h6>
                            <AppInput class="input-group-lg mb-0 roi__input"
                                      v-model="occupancy"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h4 class="col-12 mt-4 text-right">
            Total estimated saving p.a.
            <strong class="pr-2">
                {{savedAmount | currency(2, 'USD') }}
            </strong>
        </h4>
    </div>
</template>

<script>
import AppSelect from './form/AppSelect';
import AppInput from './form/AppInput';
import formatCurrency from '../filters/currency.filter';

export default {
  components: {
    AppInput,
    AppSelect,
  },
  filters: {
    currency: formatCurrency,
  },
  data() {
    return {
      vesselData: {
        vessel: null,
        vessels: [
          {
            name: 'Average Cruise Ship',
            perDayConsumption: 34.02,
          },
          {
            name: 'Large Cruise Ship',
            perDayConsumption: 37.83,
          },
          {
            name: 'Newcastlemax',
            perDayConsumption: 22.95,
          },
          {
            name: 'Ultramax',
            perDayConsumption: 15.96,
          },
          {
            name: 'Capesize',
            perDayConsumption: 39.91,
          },
          {
            name: 'Kamsarmax',
            perDayConsumption: 27.21,
          },
          {
            name: 'Aframax',
            perDayConsumption: 45.36,
          },
          {
            name: 'Suezmax',
            perDayConsumption: 51.43,
          },
          {
            name: 'VLCC',
            perDayConsumption: 90.71,
          },
          {
            name: 'Multipurpose Cargo Vessel (22,271 DWT)',
            perDayConsumption: 29.66,
          },
          {
            name: 'Multipurpose / Heavy Lift Vessel (18,680 DWT)',
            perDayConsumption: 31.20,
          },
        ],
      },
      fuelPrice: 1023,
      perDayConsumption: 148,
      saving: 3,
      occupancy: 92,
    };
  },
  computed: {
    savedAmount() {
      const result = ((365 * (this.fuelPrice * this.perDayConsumption))
        * (this.occupancy / 100));

      return result * (this.saving / 100);
    },
  },
  methods: {
    setValues(vesselData) {
      if (vesselData) {
        this.perDayConsumption = vesselData.perDayConsumption;
      } else {
        this.resetValues();
      }
    },
    resetValues() {
      this.fuelPrice = 1023;
      this.perDayConsumption = 148;
      this.saving = 3;
      this.occupancy = 92;
    },
  },
};
</script>
