<template>
    <div class="form-group">
        <label :for="field" v-if="label">{{ label }}</label>
        <input :disabled="disabled"
               :type="inputType"
               :id="field"
               :placeholder="placeholder"
               class="form-control"
               :class="{
                     'is-invalid': errors && errors.has(field)
                 }"
               :autocomplete="autocomplete"
               :maxlength="max"
               :value="value"
               @input="$emit('input', $event.target.value)"
               @change="$emit('change', $event.target.value)"
               @keydown.self.enter.exact="$emit('submit', $event.target.value)"
        >
        <slot></slot>
        <small v-if="max" class="form-text text-muted">
            {{ value ? value.length : 0}}/{{ max }}
        </small>
        <AppError v-if="errors" :errors="errors" :field="field"/>
    </div>
</template>

<script>
import AppError from './AppError';

export default {
  components: {
    AppError,
  },
  props: {
    errors: {},
    label: {},
    value: {},
    field: {},
    type: {},
    max: {},
    disabled: {},
    placeholder: {},
    autocomplete: {
      default: 'off',
    },
  },
  computed: {
    inputType() {
      return this.type || 'text';
    },
  },
};
</script>
