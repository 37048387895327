<template>
        <div class="dashboard__content container-fluid">
            <div class="container pl-0 pr-0 pb-5">
                <TheRoiCalculator/>
            </div>
        </div>
</template>
<script>
import TheRoiCalculator from '../components/TheRoiCalculator';

export default {
  components: {
    TheRoiCalculator,
  },
};
</script>
